<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <trac-back-button>KYC Menu</trac-back-button>
    <div class="mt-2 text-sm font-medium">
      {{
        showAccountDetails
          ? "Settlement Account"
          : "Complete KYC by verifying the following."
      }}
    </div>
    <div v-if="showAccountDetails" class="lg:mr-0 mr-5">
      <div
        v-if="settlementAccounts"
        class="shadow-sm rounded-sm p-8 my-4 lg:w-128 m-auto"
      >
        <div class="flex justify-center">
          <img
            class="w-24"
            :src="settlementAccounts['bank_logo']"
            alt=""
            srcset=""
          />
        </div>
        <div class="grid grid-cols-2 gap-4 mt-6">
          <p class="text-xs font-bold">Bank Name</p>
          <span class="text-xs font-medium text-gray-500">{{
            settlementAccounts["settlement_bank"]
          }}</span>
          <p class="text-xs font-bold">Account Name</p>
          <span class="text-xs font-medium text-gray-500">{{
            settlementAccounts["business_name"]
          }}</span>
          <p class="text-xs font-bold">Account Number</p>
          <span class="text-xs font-medium text-gray-500">{{
            settlementAccounts["account_number"]
          }}</span>
        </div>
      </div>
      <div class="flex justify-end">
        <trac-button @button-clicked="changeAccountDetails"
          >Change Account</trac-button
        >
      </div>
    </div>
    <form
      v-else
      @submit.prevent="verifyBank"
      class="flex justify-center flex-col bg-white rounded-md mt-12 big-shadow p-5 items-center"
    >
      <svg
        class="w-16 h-16 mt-5"
        viewBox="0 0 48 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.73438 7.92016V14.3188H46.8219V7.92016L42.6386 6.86035L2.23843 7.19546L1.73438 7.92016Z"
          fill="#B2E7FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.5878 5.81622H43.4452C45.7999 5.81622 47.65 7.66631 47.65 10.021V35.2495C47.65 37.6042 45.7999 39.4543 43.4452 39.4543H4.7614C2.40674 39.4543 0.556641 37.6042 0.556641 35.2495V10.021C0.556641 7.66631 2.40674 5.81622 4.7614 5.81622H16.6188C17.0393 2.95698 19.4781 0.770508 22.4214 0.770508H25.7852C28.7285 0.770508 31.1673 2.95698 31.5878 5.81622ZM25.7854 2.45301H22.4216C20.4033 2.45301 18.7214 3.88263 18.3009 5.81682H29.906C29.4856 3.88263 27.8037 2.45301 25.7854 2.45301ZM4.76209 7.49726H43.4459C44.8755 7.49726 45.9687 8.5905 45.9687 10.0201V13.3839H2.23924V10.0201C2.23924 8.5905 3.33248 7.49726 4.76209 7.49726ZM4.76209 37.773H43.4459C44.8755 37.773 45.9687 36.6798 45.9687 35.2501V15.0673H2.23924V35.2501C2.23924 36.6798 3.33248 37.773 4.76209 37.773Z"
          fill="#253B95"
        />
      </svg>

      <h1 class="mt-6 text-primaryBlue font-bold">Add your bank details</h1>

      <div>
        <p class="text-center tracking-normal text-sm mt-3">
          Hi! {{ name }}, kindly add your bank details.
        </p>

        <div class="mb-10 mt-16 w-96 z-90">
          <div>
            <div
              @click.stop="bankDropdown = !bankDropdown"
              class="border py-3 leading-5 mt-4 text-accentDark pl-5 w-full text-xs rounded input-area"
            >
              {{ bankname || "Select Bank" }}
            </div>
            <div
              v-click-outside="close"
              v-if="bankDropdown"
              style="max-height: 19rem"
              class="h-auto max-w-sm w-full z-50 overflow-y-auto absolute bg-white mt-1 shadow border p-2 rounded-md"
            >
              <input
                required
                type="text"
                v-model="bankSearch"
                class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
              <div class="my-2 z-50" v-for="(bank, i) in formatBanks" :key="i">
                <div
                  class="capitalize cursor-pointer hover:bg-gray-200 text-sm"
                  @click="updateBankCode(bank)"
                >
                  <p class="py-2 pl-3" data-test="bank-field">
                    {{ bank.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 w-96">
          <trac-input
            placeholder="Account Number"
            type="text"
            v-model="payload.accountNumber"
            data-test="account-number"
            required
          />
        </div>

        <div class="mt-8 w-full mb-6">
          <trac-button data-test="verify-button" class="w-full" type="submit"
            ><span class="px-12 text-center">Verify</span></trac-button
          >
        </div>
      </div>
    </form>
    <trac-modal @close="verifyModal = false" v-if="verifyModal">
      <div
        v-if="!verification.status"
        class="flex flex-col justify-center items-center"
      >
        <svg
          class="w-16 h-16 mt-20"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="44" cy="44" r="42" stroke="#CE1112" stroke-width="4" />
          <path
            d="M64 24L24 64"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24 24L64 64"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="text-center tracking-normal text-sm mt-8">
          Unable to retrieve account name. <br />
          Please check account number and try again
        </p>
        <trac-button
          @click.native="verifyModal = false"
          class="w-32 mt-8 mb-12"
          type="submit"
          ><span class="px-12 text-center">Ok</span></trac-button
        >
      </div>
      <div class="flex flex-col justify-center items-center" v-else>
        <h1 class="mt-6 text-primaryBlue font-bold">
          Confirm if this is your bank details
        </h1>
        <div class="mt-12 w-96">
          <trac-input
            placeholder="Account Name"
            type="text"
            v-model="verification.AccountName"
            disabled
          />
        </div>
        <div class="mt-8 w-96">
          <trac-input
            placeholder="Bank"
            type="text"
            v-model="bankname"
            disabled
          />
        </div>
        <div class="mt-8 w-96 mx-auto">
          <trac-input
            placeholder="Account Number"
            type="text"
            v-model="verification.AccountNumber"
            disabled
          />
        </div>
        <trac-button
          @click.native="accountVerification"
          class="w-32 mt-12 mb-12"
          type="submit"
          ><span class="px-12 text-center">Confirm</span></trac-button
        >
      </div>
    </trac-modal>
    <trac-modal v-if="successModal" :cancel="false">
      <div class="flex flex-col justify-center items-center">
        <svg
          class="w-16 h-16 mt-20"
          viewBox="0 0 170 157"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="81.4572"
            cy="80.1007"
            r="43.2554"
            fill="#11A529"
            fill-opacity="0.12"
            stroke="#B2E7FF"
            stroke-width="1.33329"
          />
          <path
            d="M60.3086 81.1842L68.4863 91.4149C69.5953 92.8022 71.6337 92.9931 72.981 91.8358L103.688 65.459"
            stroke="#253B95"
            stroke-width="5.33316"
          />
          <path
            d="M30.1113 29.4553C30.8623 32.1926 37.0518 37.7793 36.9784 28.2139C36.905 18.6486 40.7531 20.6235 44.679 22.2492"
            stroke="#0143AA"
            stroke-width="1.00759"
          />
          <path
            d="M78.4115 18.3942C80.8891 21.0813 91.2728 23.8118 85.5327 12.9136C79.7925 2.01542 85.3621 1.99895 90.8141 1.53712"
            stroke="#0143AA"
            stroke-width="1.29746"
          />
          <path
            d="M19.2072 98.4257C15.6305 95.1111 1.53402 92.5668 10.3098 106.461C19.0855 120.355 11.6796 120.96 4.47651 122.145"
            stroke="#0143AA"
            stroke-width="1.731"
          />
          <path
            d="M151.657 100.832C146.822 101.466 135.86 110.687 152.156 112.81C168.451 114.933 164.186 121.018 160.497 127.317"
            stroke="#B2E7FF"
            stroke-width="1.731"
          />
          <circle cx="64.5282" cy="137.579" r="3.79573" fill="#253B95" />
          <circle cx="87.8434" cy="148.578" r="7.59146" fill="#B2E7FF" />
          <circle cx="28.7391" cy="52.9891" r="3.79573" fill="#06B6CB" />
          <circle cx="142.612" cy="66.0033" r="7.04922" fill="#06B6CB" />
          <path
            d="M117.125 17.4727L119.468 23.8053L125.801 26.1486L119.468 28.4919L117.125 34.8246L114.782 28.4919L108.449 26.1486L114.782 23.8053L117.125 17.4727Z"
            fill="#06B6CB"
          />
          <path
            d="M4.88023 49.1934L6.19833 52.7555L9.76045 54.0736L6.19833 55.3917L4.88023 58.9538L3.56212 55.3917L0 54.0736L3.56212 52.7555L4.88023 49.1934Z"
            fill="#06B6CB"
          />
          <path
            d="M126.343 115.891L128.833 122.619L135.561 125.109L128.833 127.599L126.343 134.327L123.853 127.599L117.125 125.109L123.853 122.619L126.343 115.891Z"
            fill="#013E9E"
          />
          <path
            d="M31.8258 132.781L34.9922 136.864L40.1526 137.118L36.0699 140.284L35.8161 145.445L32.6497 141.362L27.4893 141.108L31.572 137.942L31.8258 132.781Z"
            fill="#013E9E"
          />
        </svg>

        <p class="text-center tracking-normal text-sm mt-8">
          Your bank account has been verified succesfully
        </p>
        <trac-button
          @click.native="checkOnboarding"
          class="w-32 mt-8 mb-12"
          type="submit"
          ><span class="px-12 text-center"
            >Continue Verification</span
          ></trac-button
        >
      </div>
    </trac-modal>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_USER_BUSINESS_DATA,
} from "../../browser-db-config/localStorage";
import ClickOutside from "vue-click-outside";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      name: "",
      isLoading: false,
      showAccountDetails: true,
      verifyModal: false,
      successModal: false,
      settlementAccounts: null,
      bankname: "",
      banks: [],
      bankDropdown: false,
      bankSearch: "",
      payload: {
        bankCode: "",
        accountNumber: "",
        accountNumber: "",
      },
      verification: {
        status: false,
        AccountName: "",
        AccountNumber: "",
        BankName: "",
        BankCode: "",
      },
    };
  },
  directives: {
    ClickOutside,
  },
  computed: {
    formatBanks() {
      let banksTotal = this.banks.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      return banksTotal.filter((bank) => {
        return bank.name.toLowerCase().includes(this.bankSearch.toLowerCase());
      });
    },
  },
  methods: {
    changeAccountDetails() {
      this.$router.replace({
        name: "ConfirmPassword",
        query: {
          ops: "change_settlement_account",
          onboarding: this.$route.query.onboarding,
        },
      });
    },
    async fetchAllBankAccounts() {
      await this.$store.dispatch("FETCH_ALL_BANK_ACCOUNTS");
      const res = this.$store.getters["GET_ALL_BANK_ACCOUNTS"];

      if (res.status) {
        this.settlementAccounts = (res.data || [])[0];
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    checkOnboarding() {
      if (this.$route.query.onboarding) {
        this.$router.replace({
          name: "ConfirmPinInput",
          query: {
            ops: "new_pin",
            onboarding: true,
          },
        });
      } else {
        this.$router.push({ name: "Kyc" });
      }
    },
    async accountVerification() {
      this.isLoading = true;

      const payload = {
        accountOwner: this.verification.AccountName,
        bankCode: this.verification.BankCode,
        bankName: this.verification.BankName,
        verificationReference: this.verification.AccountNumber,
        verificationType: "ACCOUNT",
      };

      let res = await this.$store.dispatch("VERIFY_ACCOUNT", payload);
      if (res.status) {
        this.verifyModal = false;
        this.successModal = true;
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message,
        });
      }
      this.isLoading = false;
    },
    async verifyBank() {
      this.isLoading = true;
      const payload = {
        ...this.payload,
        bank_account: { 
          bank_code: this.payload.bankCode,
          account_number: this.payload.accountNumber
        },
      };
      let res = await this.$store.dispatch("VERIFY_BANK_DETAILS", payload);
      if (res.status) {
        this.verifyModal = true;
        this.verification.status = true;
        this.verification.AccountName = res.data.items.AccountName;
        this.verification.AccountNumber = res.data.items.AccountNumber;
      } else {
        this.verifyModal = true;
        this.verification.status = false;
        eventBus.$emit("trac-alert", {
          message: res.message,
        });
      }
      this.isLoading = false;
    },
    updateBankCode(x) {
      this.payload.bankCode = x.bank_code;
      this.bankname = x.name;
      this.verification.BankCode = x.bank_code;
      this.verification.BankName = x.name;
      this.close();
    },
    close() {
      this.bankDropdown = false;
      this.bankSearch = "";
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchAllBankAccounts();

    this.showAccountDetails =
      this.$route.query.ops !== "change_settlement_account";

    if (!this.settlementAccounts) {
      this.showAccountDetails = false;
    }
    let res = await this.$store.dispatch("BANK_LIST");
    this.banks = res.data.items;
    this.name = GET_USER_BUSINESS_DATA().name;
    this.isLoading = false;
  },
};
</script>

<style>
.g-gray {
  background: rgba(230, 227, 227, 0.55);
}
</style>
