import { render, staticRenderFns } from "./PosPayment.vue?vue&type=template&id=25b10389&scoped=true"
import script from "./PosPayment.vue?vue&type=script&lang=js"
export * from "./PosPayment.vue?vue&type=script&lang=js"
import style0 from "./PosPayment.vue?vue&type=style&index=0&id=25b10389&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b10389",
  null
  
)

export default component.exports