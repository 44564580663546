import { render, staticRenderFns } from "./RegistersDetails.vue?vue&type=template&id=0547a6da&scoped=true"
import script from "./RegistersDetails.vue?vue&type=script&lang=js"
export * from "./RegistersDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0547a6da",
  null
  
)

export default component.exports