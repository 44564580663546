import { render, staticRenderFns } from "./CustomerLoyalty.vue?vue&type=template&id=9264b562"
import script from "./CustomerLoyalty.vue?vue&type=script&lang=js"
export * from "./CustomerLoyalty.vue?vue&type=script&lang=js"
import style0 from "./CustomerLoyalty.vue?vue&type=style&index=0&id=9264b562&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports